export function About() {
    return (
        <div>
            <section className="our-philosophy">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 theratio-align-center text-center">
                            <div className="ot-heading is-dots">
                                <span>[ Estratégia da Companhia ]</span>
                                <h2 className="main-heading text-light">Nossa Filosofia</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-5 mb-xl-0">
                            <div className="support-box">
                                <div className="inner-box">
                                    <div className="overlay flex-middle">
                                        <div className="inner">
                                            <p>Produzir imóveis que possam melhorar a qualidade de vida das famílias.</p>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <div className="icon-title">
                                            <span className="ot-flaticon-brickwall" />
                                            <h5>Nossa Missão</h5>
                                        </div>
                                        <img src="images/support1.jpg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-5 mb-xl-0">
                            <div className="support-box">
                                <div className="inner-box">
                                    <div className="overlay flex-middle">
                                        <div className="inner">
                                            <p>Ser uma empresa admirada pela qualidade de seus empreendimentos e pela forma de atuar.</p>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <div className="icon-title">
                                            <span className="ot-flaticon-paint" />
                                            <h5>Nossa Visão</h5>
                                        </div>
                                        <img src="images/support2.jpg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                            <div className="support-box">
                                <div className="inner-box">
                                    <div className="overlay flex-middle">
                                        <div className="inner">
                                            <p>Visão de longo prazo laços familiares, ética e justiça, foco na experiência e valor percebido pelo cliente, responsabilidade, respeito e acolhimento entre colaboradores.</p>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <div className="icon-title">
                                            <span className="ot-flaticon-cube" />
                                            <h5>Nossos Valores</h5>
                                        </div>
                                        <img src="images/support3.jpg" className="attachment-full size-full lazyloaded" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-counter">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4 mb-xl-0">
                            <div className="ot-counter">
                                <div>
                                    <span>[</span>
                                    <span className="num" data-to={1580} data-time={2000}>1580</span>
                                    <span>+]</span>
                                </div>
                                <h6>Clientes Felizes</h6>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4 mb-xl-0">
                            <div className="ot-counter">
                                <div>
                                    <span>[</span>
                                    <span className="num" data-to={20} data-time={2000}>20</span>
                                    <span>+]</span>
                                </div>
                                <h6>Anos de Experiência</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}