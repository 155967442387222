export function SidePanel() {

    function panel_handler() {
        var element = $('#panel-btn'),
            sidebar = $('#side-panel');
        var isActive = !element.hasClass('active');

        element.toggleClass('active', isActive);
        sidebar.toggleClass('side-panel-open', isActive);
        $('body').toggleClass('side-panel-active', isActive);
        return;
    }

    return (
        <div id="side-panel" className="side-panel">
            <a href="#" className="side-panel-close" onClick={panel_handler}><i className="ot-flaticon-close-1" /></a>
            <div className="side-panel-block">
                <div className="side-panel-wrap">
                    <div className="the-logo">
                        <a href="index-html.html">
                            <img src="images/logo-footer.svg" alt="Theratio" />
                        </a>
                    </div>
                    <div className="ot-heading ">
                        <h2 className="main-heading">Contact Info</h2>
                    </div>
                    <div className="side-panel-cinfo">
                        <ul className="panel-cinfo">
                            <li className="panel-list-item">
                                <span className="panel-list-icon"><i className="ot-flaticon-mail" /></span>
                                <span className="panel-list-text"><a className="text-light" href="mailto:extraordinaryspring.lda@gmail.com">extraordinaryspring.lda@gmail.com</a></span>
                            </li>
                            <li className="panel-list-item">
                                <span className="panel-list-icon"><i className="ot-flaticon-phone-call" /></span>
                                <span className="panel-list-text"><a className="text-light" href="mailto:+351936628922">+351 936 628 922</a></span>
                            </li>
                        </ul>
                    </div>
                    <div className="side-panel-social">
                        <ul>
                            <li><a href="https://www.facebook.com/extraordinaryspring" target="_blank"><i className="fab fa-facebook-f" /></a></li>
                            <li><a href="https://www.instagram.com/extraordinaryspring/" target="_blank"><i className="fab fa-instagram" /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}