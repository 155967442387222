import React from 'react';

import arquitetura from "./assets/breadcrumbs/arquitetura.webp"
import contato from "./assets/breadcrumbs/contato.webp"
import engenharia from "./assets/breadcrumbs/engenharia.webp"
import remodelacao from "./assets/breadcrumbs/remodelacao.webp"

import { Link, Route, Routes } from 'react-router-dom';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { SidePanel } from './components/SidePanel';

import slideOne from "./assets/slide/slideOne.webp";
import slideTwo from "./assets/slide/slideTwo.webp";
import slideThree from "./assets/slide/slideThree.webp";
import { Signature } from './components/Signature';
import { AboutHome } from './components/AboutHome';
import { Breadcrumbs } from './components/Breadcrumbs';
import { Contact } from './components/Contact';
import { About } from './components/About';
import { ServiceHome } from './components/ServiceHome';
import { SpaceHome } from './components/SpaceHome';
import { OurSkills } from './components/OurSkills';
import { Cta } from './components/Cta';
import { PhotosProjectsHome } from './components/PhotosProjectsHome';
import { Remodelacao } from './components/Remodelacao';
import { Arquitetura } from './components/Arquitetura';
import { EngenhariaConstrucao } from './components/EngenhariaConstrucao';

function App() {

  return (
    <Routes>

      <Route path="/" element={
        <div id="page" className="site">
          <Header />
          <SidePanel />
          <div id="content" className="site-content">
            <div className="p-0">
              <div className="grid-lines grid-lines-horizontal z-index-1">
                <span className="g-line-horizontal line-bottom color-line-secondary" />
              </div>
              <div className="grid-lines grid-lines-vertical z-index-1">
                <span className="g-line-vertical line-left color-line-secondary" />
                <span className="g-line-vertical line-right color-line-secondary" />
              </div>
              <div id="rev_slider_one_wrapper" className="rev_slider_wrapper fullscreen-container" data-alias="mask-showcase" data-source="gallery">
                {/* START REVOLUTION SLIDER 5.4.1 fullscreen mode */}
                <div id="rev_slider_one" className="rev_slider fullscreenbanner" style={{ display: 'none' }} data-version="5.4.1">
                  <ul>
                    {/* SLIDE 1 */}
                    <li data-index="rs-70" data-transition="fade" data-slotamount="default" data-hideafterloop={0} data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed={300} data-thumb={slideOne} data-rotate={0} data-saveperformance="off" data-title data-param1={1} data-param2 data-param3 data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>
                      {/* MAIN IMAGE */}
                      <img src={slideOne} data-bgcolor="rgba(255,255,255,0)" style={{}} data-bgposition="center center" data-bgfit="cover" className="rev-slidebg" data-bgrepeat="no-repeat" data-bgparallax="off" data-no-retina />
                      {/* LAYER 1  right image overlay dark*/}
                      {/* LAYER 3  Thin text title*/}
                      <div
                        className="tp-caption tp-resizeme tp-caption-big"
                        id="slide-70-layer-1"
                        data-x="['center','center','center','center']"
                        data-hoffset="['56','46','34','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['-64','-72','-65','-50']"
                        data-fontsize="['206',150','0','0']"
                        data-lineheight="['206','170','127','80']"
                        data-letterspacing="['104','85','63','39']"
                        data-fontweight="['900','900','900','900']"
                        data-color="['transparent','transparent','transparent','transparent']"
                        data-whitespace="nowrap"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:500,&quot;split&quot;:&quot;chars&quot;,&quot;splitdelay&quot;:0.1,&quot;speed&quot;:500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:0px;s:inherit;e:inherit;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power4.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['center','center','center','center']">
                        Arquit.
                      </div>
                      {/* LAYER 4  Bold Title*/}
                      <div
                        className="tp-caption tp-resizeme tp-caption-main" id="slide-70-layer-2"
                        data-x="['center','center','center','center']"
                        data-hoffset="['2','0','0','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['-56','-63','-60','-65']"
                        data-fontsize="['93','72','55','40']"
                        data-lineheight="['83','70','51','55']"
                        data-color="['#fff','#fff','#fff','#fff']"
                        data-fontweight="['200','200','200','200']"
                        data-whitespace="nowrap"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:900,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['left','left','left','left']">
                        Arquitetura
                      </div>
                      {/* LAYER 5  Paragraph*/}
                      <div
                        className="tp-caption tp-resizeme tp-desc"
                        id="slide-70-layer-3"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['54','43','31','15']"
                        data-fontsize="['19','18','17','16']"
                        data-lineheight="['33','27','28','24']"
                        data-width="['818','630','500','417']"
                        data-weight="['500','500','500','500']"
                        data-color="['#fff','#fff','#fff','#fff']"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:1200,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['center','center','center','center']">
                        Nós pensamos em cada etapa da construção do seu sonho, sabemos que são muitas ideias, mas juntos iremos organizá-las e definir como ficará o projeto da sua casa, levando em consideração cada detalhe de acordo com suas preferências.
                      </div>
                      {/* LAYER 6  Read More*/}
                      <div
                        className="tp-caption rev-btn" id="slide-70-layer-4"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['170','140','119','110']"
                        data-fontsize="['13','18','17','0']"
                        data-lineheight="['25','18','16','16']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:1500,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]"
                        data-textalign="['center','center','center','center']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]">
                        <Link to="/arquitetura" className="octf-btn octf-btn-primary btn-slider btn-large">Visualizar Projeto</Link>
                      </div>
                    </li>
                    {/* SLIDE 1 */}
                    <li data-index="rs-71"
                      data-transition="fade"
                      data-slotamount="default"
                      data-hideafterloop={0}
                      data-hideslideonmobile="off"
                      data-easein="default"
                      data-easeout="default"
                      data-masterspeed={300}
                      data-thumb={slideTwo}
                      data-rotate={0}
                      data-saveperformance="off"
                      data-title data-param1={1}
                      data-param2
                      data-param3
                      data-param4
                      data-param5
                      data-param6
                      data-param7
                      data-param8
                      data-param9
                      data-param10
                      data-description>
                      {/* MAIN IMAGE */}
                      <img src={slideTwo} data-bgcolor="#ffffff" style={{}} data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-bgparallax="off" data-no-retina />
                      {/* LAYER 3  Thin text title*/}
                      <div className="tp-caption tp-resizeme tp-caption-big"
                        id="slide-71-layer-1"
                        data-x="['center','center','center','center']"
                        data-hoffset="['56','46','34','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['-64','-72','-65','-50']"
                        data-fontsize="['206',150','0','0']"
                        data-lineheight="['206','170','127','80']"
                        data-letterspacing="['104','85','63','39']"
                        data-fontweight="['900','900','900','900']"
                        data-color="['transparent','transparent','transparent','transparent']"
                        data-whitespace="nowrap"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:500,&quot;split&quot;:&quot;chars&quot;,&quot;splitdelay&quot;:0.1,&quot;speed&quot;:500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:0px;s:inherit;e:inherit;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power4.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['center','center','center','center']">
                        Engen.
                      </div>
                      {/* LAYER 4  Bold Title*/}
                      <div className="tp-caption tp-resizeme tp-caption-main"
                        id="slide-71-layer-2"
                        data-x="['center','center','center','center']"
                        data-hoffset="['2','0','0','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['-56','-63','-60','-65']"
                        data-fontsize="['93','72','55','40']"
                        data-lineheight="['83','70','51','55']"
                        data-color="['#fff','#fff','#fff','#fff']"
                        data-fontweight="['200','200','200','200']"
                        data-whitespace="nowrap"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:900,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['left','left','left','left']">
                        Engenharia Civil
                      </div>
                      {/* LAYER 5  Paragraph*/}
                      <div className="tp-caption tp-resizeme tp-desc"
                        id="slide-71-layer-3"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['54','43','31','15']"
                        data-fontsize="['19','18','17','16']"
                        data-lineheight="['33','27','28','24']"
                        data-width="['818','630','500','417']"
                        data-weight="['500','500','500','500']"
                        data-color="['#fff','#fff','#fff','#fff']"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:1200,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['center','center','center','center']">
                        Sempre tivemos em mente algo além de uma simples construção, pensamos sobre os vários significados impactantes que uma residência têm, como família, refúgio, momentos inesquecíveis, começo de uma vida nova e realização de sonhos.
                      </div>
                      {/* LAYER 6  Read More*/}
                      <div className="tp-caption rev-btn"
                        id="slide-71-layer-4"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['170','140','119','110']"
                        data-fontsize="['13','18','17','0']"
                        data-lineheight="['25','18','16','16']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:1500,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['center','center','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]">
                        <Link to="/engenharia-construcao" className="octf-btn octf-btn-primary btn-slider btn-large">Visualizar Projeto</Link>
                      </div>
                    </li>
                    {/* SLIDE 1 */}
                    <li data-index="rs-72" data-transition="fade" data-slotamount="default" data-hideafterloop={0} data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed={300} data-thumb={slideThree} data-rotate={0} data-saveperformance="off" data-title data-param1={1} data-param2 data-param3 data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>
                      {/* MAIN IMAGE */}
                      <img src={slideThree} data-bgcolor="#ffffff" style={{}} data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-bgparallax="off" data-no-retina />
                      {/* LAYER 3  Thin text title*/}
                      <div className="tp-caption tp-resizeme tp-caption-big"
                        id="slide-72-layer-1"
                        data-x="['center','center','center','center']"
                        data-hoffset="['56','46','34','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['-64','-72','-65','-50']"
                        data-fontsize="['206',150','0','0']"
                        data-lineheight="['206','170','127','80']"
                        data-letterspacing="['104','85','63','39']"
                        data-fontweight="['900','900','900','900']"
                        data-color="['transparent','transparent','transparent','transparent']"
                        data-whitespace="nowrap"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:500,&quot;split&quot;:&quot;chars&quot;,&quot;splitdelay&quot;:0.1,&quot;speed&quot;:500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:0px;s:inherit;e:inherit;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power4.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['center','center','center','center']">
                        Remod.
                      </div>
                      {/* LAYER 4  Bold Title*/}
                      <div className="tp-caption tp-resizeme tp-caption-main"
                        id="slide-72-layer-2"
                        data-x="['center','center','center','center']"
                        data-hoffset="['2','0','0','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['-56','-63','-60','-65']"
                        data-fontsize="['93','72','55','40']"
                        data-lineheight="['83','70','51','55']"
                        data-color="['#fff','#fff','#fff','#fff']"
                        data-fontweight="['200','200','200','200']"
                        data-whitespace="nowrap" data-type="text"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:900,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['left','left','left','left']">
                        Remodelação
                      </div>
                      {/* LAYER 5  Paragraph*/}
                      <div className="tp-caption tp-resizeme tp-desc"
                        id="slide-72-layer-3"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['54','43','31','15']"
                        data-fontsize="['19','18','17','16']"
                        data-lineheight="['33','27','28','24']"
                        data-width="['818','630','500','417']"
                        data-weight="['500','500','500','500']"
                        data-color="['#fff','#fff','#fff','#fff']"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:1200,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['center','center','center','center']">
                        A Extraordinary, com toda a sua expertise e paixão pelo que faz, quer fazer com que estes estejam presentes na vida de nossos clientes, com um toque arquitetônico especial e engenharia otimizada. E por fim, fazer da sua identidade a nossa ao projetar a residência.
                      </div>
                      {/* LAYER 6  Read More*/}
                      <div className="tp-caption rev-btn"
                        id="slide-72-layer-4"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['170','140','119','110']"
                        data-fontsize="['13','18','17','0']"
                        data-lineheight="['25','18','16','16']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:1500,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['center','center','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]">
                        <Link to="/remodelacao" className="octf-btn octf-btn-primary btn-slider btn-large">Visualizar Projeto</Link>
                      </div>
                    </li>
                  </ul>
                  <div className="tp-bannertimer" />
                </div>
              </div>
              <div className="banner-desc-1">
                <ul>
                  <li><a href="https://www.facebook.com/extraordinaryspring" target="_blank"><span>facebook</span></a></li>
                  <li><a href="https://www.instagram.com/extraordinaryspring/" target="_blank"><span>instagram</span></a></li>
                </ul>
              </div>
            </div>
            <Signature />
            <AboutHome />
            <SpaceHome />
            <ServiceHome />
            <Cta />
          </div>
          <Footer />
        </div>
      } />

      <Route path="/remodelacao" element={
        <div id="page" className="site">
          <Header />
          <SidePanel />
          <Breadcrumbs namePage="Remodelação" image={remodelacao} />
          <Remodelacao />
          <Footer />
        </div>
      } />

      <Route path="/engenharia-construcao" element={
        <div id="page" className="site">
          <Header />
          <SidePanel />
          <Breadcrumbs namePage="Engenharia e Construção" image={engenharia} />
          <EngenhariaConstrucao />
          <Footer />
        </div>
      } />

      <Route path="/arquitetura" element={
        <div id="page" className="site">
          <Header />
          <SidePanel />
          <Breadcrumbs namePage="Arquitetura" image={arquitetura} />
          <Arquitetura />
          <Footer />
        </div>
      } />

      <Route path="/contact" element={
        <div id="page" className="site">
          <Header />
          <SidePanel />
          <Breadcrumbs namePage="Contato" image={contato} />
          <Contact />
          <Footer />
        </div>
      } />

      <Route path="/about" element={
        <div id="page" className="site">
          <Header />
          <SidePanel />
          <Breadcrumbs namePage="Sobre Nós" image={engenharia} />
          <AboutHome />
          <About />
          <Footer />
        </div>
      } />

      {/* Use it in this way, and it should work: */}
      <Route path='*' element={
        <div id="page" className="site">
          <Header />
          <SidePanel />
          <div id="content" className="site-content">
            <div className="p-0">
              <div className="grid-lines grid-lines-horizontal z-index-1">
                <span className="g-line-horizontal line-bottom color-line-secondary" />
              </div>
              <div className="grid-lines grid-lines-vertical z-index-1">
                <span className="g-line-vertical line-left color-line-secondary" />
                <span className="g-line-vertical line-right color-line-secondary" />
              </div>
              <div id="rev_slider_one_wrapper" className="rev_slider_wrapper fullscreen-container" data-alias="mask-showcase" data-source="gallery">
                {/* START REVOLUTION SLIDER 5.4.1 fullscreen mode */}
                <div id="rev_slider_one" className="rev_slider fullscreenbanner" style={{ display: 'none' }} data-version="5.4.1">
                  <ul>
                    {/* SLIDE 1 */}
                    <li data-index="rs-70" data-transition="fade" data-slotamount="default" data-hideafterloop={0} data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed={300} data-thumb={slideOne} data-rotate={0} data-saveperformance="off" data-title data-param1={1} data-param2 data-param3 data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>
                      {/* MAIN IMAGE */}
                      <img src={slideOne} data-bgcolor="rgba(255,255,255,0)" style={{}} data-bgposition="center center" data-bgfit="cover" className="rev-slidebg" data-bgrepeat="no-repeat" data-bgparallax="off" data-no-retina />
                      {/* LAYER 1  right image overlay dark*/}
                      {/* LAYER 3  Thin text title*/}
                      <div
                        className="tp-caption tp-resizeme tp-caption-big"
                        id="slide-70-layer-1"
                        data-x="['center','center','center','center']"
                        data-hoffset="['56','46','34','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['-64','-72','-65','-50']"
                        data-fontsize="['206',150','0','0']"
                        data-lineheight="['206','170','127','80']"
                        data-letterspacing="['104','85','63','39']"
                        data-fontweight="['900','900','900','900']"
                        data-color="['transparent','transparent','transparent','transparent']"
                        data-whitespace="nowrap"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:500,&quot;split&quot;:&quot;chars&quot;,&quot;splitdelay&quot;:0.1,&quot;speed&quot;:500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:0px;s:inherit;e:inherit;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power4.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['center','center','center','center']">
                        Arquit.
                      </div>
                      {/* LAYER 4  Bold Title*/}
                      <div
                        className="tp-caption tp-resizeme tp-caption-main" id="slide-70-layer-2"
                        data-x="['center','center','center','center']"
                        data-hoffset="['2','0','0','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['-56','-63','-60','-65']"
                        data-fontsize="['93','72','55','40']"
                        data-lineheight="['83','70','51','55']"
                        data-color="['#fff','#fff','#fff','#fff']"
                        data-fontweight="['200','200','200','200']"
                        data-whitespace="nowrap"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:900,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['left','left','left','left']">
                        Arquitetura
                      </div>
                      {/* LAYER 5  Paragraph*/}
                      <div
                        className="tp-caption tp-resizeme tp-desc"
                        id="slide-70-layer-3"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['54','43','31','15']"
                        data-fontsize="['19','18','17','16']"
                        data-lineheight="['33','27','28','24']"
                        data-width="['818','630','500','417']"
                        data-weight="['500','500','500','500']"
                        data-color="['#fff','#fff','#fff','#fff']"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:1200,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['center','center','center','center']">
                        Nós pensamos em cada etapa da construção do seu sonho, sabemos que são muitas ideias, mas juntos iremos organizá-las e definir como ficará o projeto da sua casa, levando em consideração cada detalhe de acordo com suas preferências.
                      </div>
                      {/* LAYER 6  Read More*/}
                      <div
                        className="tp-caption rev-btn" id="slide-70-layer-4"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['170','140','119','110']"
                        data-fontsize="['13','18','17','0']"
                        data-lineheight="['25','18','16','16']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:1500,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]"
                        data-textalign="['center','center','center','center']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]">
                        <Link to="/arquitetura" className="octf-btn octf-btn-primary btn-slider btn-large">Visualizar Projeto</Link>
                      </div>
                    </li>
                    {/* SLIDE 1 */}
                    <li data-index="rs-71"
                      data-transition="fade"
                      data-slotamount="default"
                      data-hideafterloop={0}
                      data-hideslideonmobile="off"
                      data-easein="default"
                      data-easeout="default"
                      data-masterspeed={300}
                      data-thumb={slideTwo}
                      data-rotate={0}
                      data-saveperformance="off"
                      data-title data-param1={1}
                      data-param2
                      data-param3
                      data-param4
                      data-param5
                      data-param6
                      data-param7
                      data-param8
                      data-param9
                      data-param10
                      data-description>
                      {/* MAIN IMAGE */}
                      <img src={slideTwo} data-bgcolor="#ffffff" style={{}} data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-bgparallax="off" data-no-retina />
                      {/* LAYER 3  Thin text title*/}
                      <div className="tp-caption tp-resizeme tp-caption-big"
                        id="slide-71-layer-1"
                        data-x="['center','center','center','center']"
                        data-hoffset="['56','46','34','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['-64','-72','-65','-50']"
                        data-fontsize="['206',150','0','0']"
                        data-lineheight="['206','170','127','80']"
                        data-letterspacing="['104','85','63','39']"
                        data-fontweight="['900','900','900','900']"
                        data-color="['transparent','transparent','transparent','transparent']"
                        data-whitespace="nowrap"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:500,&quot;split&quot;:&quot;chars&quot;,&quot;splitdelay&quot;:0.1,&quot;speed&quot;:500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:0px;s:inherit;e:inherit;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power4.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['center','center','center','center']">
                        Engen.
                      </div>
                      {/* LAYER 4  Bold Title*/}
                      <div className="tp-caption tp-resizeme tp-caption-main"
                        id="slide-71-layer-2"
                        data-x="['center','center','center','center']"
                        data-hoffset="['2','0','0','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['-56','-63','-60','-65']"
                        data-fontsize="['93','72','55','40']"
                        data-lineheight="['83','70','51','55']"
                        data-color="['#fff','#fff','#fff','#fff']"
                        data-fontweight="['200','200','200','200']"
                        data-whitespace="nowrap"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:900,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['left','left','left','left']">
                        Engenharia Civil
                      </div>
                      {/* LAYER 5  Paragraph*/}
                      <div className="tp-caption tp-resizeme tp-desc"
                        id="slide-71-layer-3"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['54','43','31','15']"
                        data-fontsize="['19','18','17','16']"
                        data-lineheight="['33','27','28','24']"
                        data-width="['818','630','500','417']"
                        data-weight="['500','500','500','500']"
                        data-color="['#fff','#fff','#fff','#fff']"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:1200,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['center','center','center','center']">
                        Sempre tivemos em mente algo além de uma simples construção, pensamos sobre os vários significados impactantes que uma residência têm, como família, refúgio, momentos inesquecíveis, começo de uma vida nova e realização de sonhos.
                      </div>
                      {/* LAYER 6  Read More*/}
                      <div className="tp-caption rev-btn"
                        id="slide-71-layer-4"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['170','140','119','110']"
                        data-fontsize="['13','18','17','0']"
                        data-lineheight="['25','18','16','16']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:1500,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['center','center','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]">
                        <Link to="/engenharia-construcao" className="octf-btn octf-btn-primary btn-slider btn-large">Visualizar Projeto</Link>
                      </div>
                    </li>
                    {/* SLIDE 1 */}
                    <li data-index="rs-72" data-transition="fade" data-slotamount="default" data-hideafterloop={0} data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed={300} data-thumb={slideThree} data-rotate={0} data-saveperformance="off" data-title data-param1={1} data-param2 data-param3 data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>
                      {/* MAIN IMAGE */}
                      <img src={slideThree} data-bgcolor="#ffffff" style={{}} data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-bgparallax="off" data-no-retina />
                      {/* LAYER 3  Thin text title*/}
                      <div className="tp-caption tp-resizeme tp-caption-big"
                        id="slide-72-layer-1"
                        data-x="['center','center','center','center']"
                        data-hoffset="['56','46','34','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['-64','-72','-65','-50']"
                        data-fontsize="['206',150','0','0']"
                        data-lineheight="['206','170','127','80']"
                        data-letterspacing="['104','85','63','39']"
                        data-fontweight="['900','900','900','900']"
                        data-color="['transparent','transparent','transparent','transparent']"
                        data-whitespace="nowrap"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:500,&quot;split&quot;:&quot;chars&quot;,&quot;splitdelay&quot;:0.1,&quot;speed&quot;:500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:0px;s:inherit;e:inherit;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power4.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['center','center','center','center']">
                        Remod.
                      </div>
                      {/* LAYER 4  Bold Title*/}
                      <div className="tp-caption tp-resizeme tp-caption-main"
                        id="slide-72-layer-2"
                        data-x="['center','center','center','center']"
                        data-hoffset="['2','0','0','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['-56','-63','-60','-65']"
                        data-fontsize="['93','72','55','40']"
                        data-lineheight="['83','70','51','55']"
                        data-color="['#fff','#fff','#fff','#fff']"
                        data-fontweight="['200','200','200','200']"
                        data-whitespace="nowrap" data-type="text"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:900,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['left','left','left','left']">
                        Remodelação
                      </div>
                      {/* LAYER 5  Paragraph*/}
                      <div className="tp-caption tp-resizeme tp-desc"
                        id="slide-72-layer-3"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['54','43','31','15']"
                        data-fontsize="['19','18','17','16']"
                        data-lineheight="['33','27','28','24']"
                        data-width="['818','630','500','417']"
                        data-weight="['500','500','500','500']"
                        data-color="['#fff','#fff','#fff','#fff']"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:1200,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['center','center','center','center']">
                        A Extraordinary, com toda a sua expertise e paixão pelo que faz, quer fazer com que estes estejam presentes na vida de nossos clientes, com um toque arquitetônico especial e engenharia otimizada. E por fim, fazer da sua identidade a nossa ao projetar a residência.
                      </div>
                      {/* LAYER 6  Read More*/}
                      <div className="tp-caption rev-btn"
                        id="slide-72-layer-4"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['center','center','center','center']"
                        data-voffset="['170','140','119','110']"
                        data-fontsize="['13','18','17','0']"
                        data-lineheight="['25','18','16','16']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-responsive_offset="on"
                        data-frames="[{&quot;delay&quot;:1500,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:50px;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]" data-textalign="['center','center','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]">
                        <Link to="/remodelacao" className="octf-btn octf-btn-primary btn-slider btn-large">Visualizar Projeto</Link>
                      </div>
                    </li>
                  </ul>
                  <div className="tp-bannertimer" />
                </div>
              </div>
              <div className="banner-desc-1">
                <ul>
                  <li><a href="https://www.facebook.com/extraordinaryspring" target="_blank"><span>facebook</span></a></li>
                  <li><a href="https://www.instagram.com/extraordinaryspring/" target="_blank"><span>instagram</span></a></li>
                </ul>
              </div>
            </div>
            <Signature />
            <AboutHome />
            <SpaceHome />
            <ServiceHome />
            <PhotosProjectsHome />
            <Cta />
            <OurSkills />
          </div>
          <Footer />
        </div>
      } />

    </Routes>

  );
}

export default App;
