import { Link } from "react-router-dom";

import arquitecture from "../assets/services/arquitecture.webp"
import { Cta } from "./Cta";

export function Arquitetura() {
    return (
        <div>
            <section className="services-single">
                <div className="container">
                    <div className="row">
                        <div className="widget-area col-lg-3 col-md-12">
                            <div className="widget widget_nav_menu">
                                <ul className="services-menu">
                                    <li><Link to="/remodelacao"><span>01.</span> Remodelação</Link></li>
                                    <li><Link to="/engenharia-construcao"><span>02.</span> Engenharia - Construtora</Link></li>
                                    <li className="current-menu-item"><Link to="/arquitetura"><span>03.</span> Arquitetura</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-12">
                            <div className="services-detail-content">
                                <div className="ot-heading ">
                                    <span>[ Extraordinary Spring ]</span>
                                    <h2 className="main-heading">Arquitetura</h2>
                                </div>
                                <p>
                                    Fazemos o projeto arquitetônico completo para erguer o edifício do zero.
                                    Para aproveitar todo o potencial de um terreno, o projeto deve ser pensado de forma personalizada desde o primeiro traço.
                                    Não é apenas sobre aproveitar metros quadrados. Um espaço de qualidade é pensado a partir das atividades, do contexto e do bem-estar humano.
                                    O que queremos expressar com a arquitetura é parte fundamental.
                                </p>
                            </div>

                            <section className="principes no-padding">
                                <div className="row m-0">
                                    <div className="col-xl-6 col-lg-12 col-md-12 p-0 text-center align-self-center">
                                        <img src={arquitecture} alt="arquitetura" />
                                    </div>
                                    <div className="col-xl-6 col-lg-12 col-md-12 p-0 mt-5 mt-lg-0 align-self-center">
                                        <div className="main-principes">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12 mb-4 mb-md-0">
                                                    <div className="process-classic">
                                                        <h6>RESIDENCIAIS</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 mb-4 mb-md-0">
                                                    <div className="process-classic">
                                                        <h6>COMERCIAIS</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 mb-4 mb-md-0">
                                                    <div className="process-classic mb-4 mb-md-0">
                                                        <h6>PREDIAIS</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="process-classic mb-md-0">
                                                        <h6>INDUSTRIAIS</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="process-classic mb-md-0">
                                                        <h6>REGULARIZAÇÕES / ORGÃOS PÚBLICOS</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="process-classic mb-md-0">
                                                        <h6>PROJETO EM 3D</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="row">
                                <div className="col-lg-12">
                                    <h3>Serviços</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <ul className="style-default unstyle">
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Box e Vidraçaria: vidros e similares (instalação)</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Carpintaria comum e de forma</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Concretagem e Polimento</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Construção Civil: Construção de casas</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Construção Civil: Serviços em geral</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Divisórias (diversos materiais)</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Fechamento de varandas</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Ferragem (corte, dobra e armação)</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Fundação</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Gesso: Comum e Acartonado (Construção a seco em tetos e divisórias)</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Hidráulica, Esgoto e Águas Pluviais</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Mármores, Granitos e Pedras</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Pavimentação e Rampa</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <ul className="style-default unstyle">
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Pintura de fachadas</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Pintura decoração (Grafiato, textura e outros)</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Pintura em geral: área interna e externa</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Pisos em geral (antiderrapante e de acabamentos, cerâmica, porcelanato, pedras e outros)</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Portas, Portões e Janelas (instalação)</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Pladur</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Revestimentos (pisos e paredes – diversos materiais)</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Revitalização: área interna e externa</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Sinteco e calafetagem</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Telhado e Cobertura</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Teto e Forro (Recuperação e Construção)</span>
                                        </li>
                                        <li>
                                            <span><i className="fas fa-minus" /></span>
                                            <span> Remodelação e Manutenção em geral</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="theratio-separato" />
                            </div>
                        </div>

                    </div>
                </div>

            </section >
            <Cta />
        </div>
    );
}