export function OurSkills() {
    return (
        <section className="skill-1">
            <div className="grid-lines grid-lines-vertical">
                <span className="g-line-vertical line-left color-line-default" />
                <span className="g-line-vertical line-center color-line-default" />
                <span className="g-line-vertical line-right color-line-default" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 align-self-center">
                        <div className="skill-content-1 mr-xl-70">
                            <div className="ot-heading is-dots">
                                <span>[ our skills ]</span>
                                <h2 className="main-heading">The Core Company Values</h2>
                            </div>
                            <div className="space-20" />
                            <div className="space-5" />
                            <p>We are constantly growing, learning, and improving and our partners are steadily increasing. 200 projects is a sizable number.</p>
                            <div className="space-10" />
                            <div className="ot-progress pb-3" data-percent={65} data-processed="true">
                                <div className="overflow">
                                    <span className="pname f-left">interior sketch</span>
                                </div>
                                <div className="iprogress">
                                    <div className="progress-bar"><span className="ppercent">65%</span></div>
                                </div>
                            </div>
                            <div className="ot-progress pb-3" data-percent={85} data-processed="true">
                                <div className="overflow"><span className="pname f-left">3D Modeling</span></div>
                                <div className="iprogress">
                                    <div className="progress-bar"><span className="ppercent">85%</span></div>
                                </div>
                            </div>
                            <div className="ot-progress pb-3" data-percent={55} data-processed="true">
                                <div className="overflow"><span className="pname f-left">2D Planning</span></div>
                                <div className="iprogress">
                                    <div className="progress-bar"><span className="ppercent">55%</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 mt-4 mt-lg-0 align-self-center">
                        <div className="skill-img-1">
                            <img src="images/image3-home1.png" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}