import { Link } from "react-router-dom";

export function ServiceHome() {
    return (
        <section className="services-1">
            <div className="grid-lines grid-lines-vertical">
                <span className="g-line-vertical line-left color-line-default" />
                <span className="g-line-vertical line-center color-line-default" />
                <span className="g-line-vertical line-right color-line-default" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center theratio-align-center">
                        <div className="ot-heading is-dots">
                            <span>[ NOSSOS SERVIÇOS ]</span>
                            <h2 className="main-heading">O Que Podemos Oferecer</h2>
                        </div>
                        <div className="space-50" />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="icon-box icon-box--bg-img icon-box--icon-top icon-box--is-line-hover icon-bg-1 text-center">
                            <div className="icon-main"><img src="images/iconbox1.2.png" /></div>
                            <div className="content-box">
                                <h5><Link to="/arquitetura">Arquitetura</Link></h5>
                                <p>Construção moderna e funcional com modelagem do ambiente físico.</p>
                            </div>
                            <div className="link-box">
                                <Link to="/arquitetura" className="btn-details">LER MAIS</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="icon-box icon-box--bg-img icon-box--icon-top icon-box--is-line-hover icon-bg-2 text-center">
                            <div className="icon-main"><img src="images/iconbox2.png" /></div>
                            <div className="content-box">
                                <h5><Link to="/engenharia-construcao">Engenharia &amp; Construção</Link></h5>
                                <p>Planejamento e execução das obras e empreendimentos.</p>
                            </div>
                            <div className="link-box">
                                <Link to="/engenharia-construcao" className="btn-details">LER MAIS</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="icon-box icon-box--bg-img icon-box--icon-top icon-box--is-line-hover icon-bg-3 text-center">
                            <div className="icon-main"><img src="images/iconbox3.png" /></div>
                            <div className="content-box">
                                <h5><Link to="/remodelacao">Remodelação</Link></h5>
                                <p>Reorganizar a distribuição da casa para aproveitar o espaço ao máximo.</p>
                            </div>
                            <div className="link-box">
                                <Link to="/remodelacao" className="btn-details">LER MAIS</Link>
                            </div>
                        </div>
                    </div>
                    <div className="space-120" />
                </div>
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4 mb-xl-0">
                        <div className="ot-counter">
                            <div>
                                <span>[</span>
                                <span className="num" data-to={1580} data-time={2000}>1580</span>
                                <span>+]</span>
                            </div>
                            <h6>Clientes Felizes</h6>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4 mb-xl-0">
                        <div className="ot-counter">
                            <div>
                                <span>[</span>
                                <span className="num" data-to={20} data-time={2000}>20</span>
                                <span>+]</span>
                            </div>
                            <h6>Anos de Experiência</h6>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}