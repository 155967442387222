import { Link } from "react-router-dom";

export function Header() {
    function panel_handler() {
        var element = $('#panel-btn'),
            sidebar = $('#side-panel');
        var isActive = !element.hasClass('active');

        element.toggleClass('active', isActive);
        sidebar.toggleClass('side-panel-open', isActive);
        $('body').toggleClass('side-panel-active', isActive);
        return;
    }

    /* --------------------------------------------------
    * mobile menu
    * --------------------------------------------------*/
    function mmenu_handler() {
        var element = $('#mmenu-toggle'),
            mmenu = $('#mmenu-wrapper');

        var isActive = !element.hasClass('active');

        element.toggleClass('active', isActive);
        mmenu.toggleClass('mmenu-open', isActive);
        $('body').toggleClass('mmenu-active', isActive);
        return;
    }

    return (
        <header id="site-header" className="site-header header-transparent">
            {/* Main Header start */}
            <div className="octf-main-header is-fixed">
                <div className="octf-area-wrap">
                    <div className="container-fluid octf-mainbar-container">
                        <div className="octf-mainbar">
                            <div className="octf-mainbar-row octf-row">
                                <div className="octf-col logo-col no-padding">
                                    <div id="site-logo" className="site-logo">
                                        <a href="/">
                                            <img src="images/sitelogo.svg" alt="Theratio" />
                                        </a>
                                    </div>
                                </div>
                                <div className="octf-col menu-col no-padding">
                                    <nav id="site-navigation" className="main-navigation">
                                        <ul className="menu">
                                            <li><a href="/">Home</a></li>
                                            <li><Link to="/about">Sobre Nós</Link></li>
                                            <li className="menu-item-has-children"><a href="#">Nosso Serviço</a>
                                                <ul className="sub-menu">
                                                    <li><Link to="/remodelacao">Remodelação</Link></li>
                                                    <li><Link to="/engenharia-construcao">Engenharia - Construtora</Link></li>
                                                    <li><Link to="/arquitetura">Arquitetura</Link></li>
                                                </ul>
                                            </li>
                                            <li><Link to="/contact">Contatos</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="octf-col cta-col text-right no-padding">
                                    {/* Call To Action */}
                                    <div className="octf-btn-cta">
                                        <div className="octf-sidepanel octf-cta-header" onClick={panel_handler}>
                                            <div className="site-overlay panel-overlay" />
                                            <div id="panel-btn" className="panel-btn octf-cta-icons" >
                                                <i className="ot-flaticon-menu" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header_mobile">
                <div className="container-fluid">
                    <div className="octf-mainbar-row octf-row">
                        <div className="octf-col">
                            <div className="mlogo_wrapper clearfix">
                                <div className="mobile_logo">
                                    <a href="index-2.html">
                                        <img src="images/sitelogo.svg" alt="Theratio" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="octf-col justify-content-end">
                            <div className="octf-search octf-cta-header">
                                <div className="toggle_search octf-cta-icons">
                                    <i className="ot-flaticon-search" />
                                </div>
                                {/* Form Search on Header */}
                                <div className="h-search-form-field collapse">
                                    <div className="h-search-form-inner">
                                        <form role="search" method="get" className="search-form">
                                            <input type="search" className="search-field" placeholder="SEARCH..." defaultValue={0} name="s" />
                                            <button type="submit" className="search-submit"><i className="ot-flaticon-search" /></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="octf-menu-mobile octf-cta-header">
                                <div id="mmenu-toggle" className="mmenu-toggle" onClick={mmenu_handler}>
                                    <button><i className="ot-flaticon-menu" /></button>
                                </div>
                                <div className="site-overlay mmenu-overlay" />
                                <div id="mmenu-wrapper" className="mmenu-wrapper on-right">
                                    <div className="mmenu-inner">
                                        <a className="mmenu-close" href="#" onClick={mmenu_handler}><i className="ot-flaticon-right-arrow" /></a>
                                        <div className="mobile-nav">
                                            <ul id="menu-main-menu" className="mobile_mainmenu none-style">
                                                <li><a href="/">Home</a></li>
                                                <li><Link to="/about">Sobre Nós</Link></li>
                                                <li><Link to="/contact">Contacts</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}