import { Link } from "react-router-dom";

export function Cta() {
    return (
        <section className="cta">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 mb-4 mb-lg-0">
                        <h2 className="text-light mb-0">Faça sua cotação agora mesmo!</h2>
                        <div className="space-5" />
                    </div>
                    <div className="col-lg-4 text-left text-lg-right align-self-center">
                        <div className="ot-button">
                            <Link to="/contact" className="octf-btn octf-btn-light border-hover-light">Entrar em contato</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}