import { Link } from "react-router-dom";

type BreadcrumbContextType = {
    namePage: string;
    image: string;
}

export function Breadcrumbs(props: BreadcrumbContextType) {
    return (
        <div id="content" className="site-content">
            <div className="page-header dtable text-center header-transparent" style={{ backgroundImage: `url(${props.image})` }}>
                <div className="dcell">
                    <div className="container">
                        <h1 className="page-title">{props.namePage}</h1>
                        <ul id="breadcrumbs" className="breadcrumbs none-style">
                            <li><a href="/">Home</a></li>
                            <li className="active">{props.namePage}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}