export function PhotosProjectsHome() {
    return (
        <section className="p-0">
            <div className="projects-grid pf_4_cols style-2 p-info-s2 img-scale w-auto no-gaps mx-0">
                <div className="grid-sizer" />
                <div className="project-item category-19 thumb2x">
                    <div className="projects-box">
                        <div className="projects-thumbnail">
                            <a href="portfolio-standar.html">
                                <img src="images/projects-grid/project-metro1.jpg" />
                            </a>
                            <div className="overlay">
                                <h5>Stylish Family Appartment</h5>
                                <i className="ot-flaticon-add" />
                            </div>
                        </div>
                        <div className="portfolio-info">
                            <div className="portfolio-info-inner">
                                <h5><a className="title-link" href="portfolio-standar.html">Stylish Family Appartment</a></h5>
                                <p className="portfolio-cates"><a href="#">Interior</a></p>
                            </div>
                            <a className="overlay" href="portfolio-standar.html" />
                        </div>
                    </div>
                </div>
                <div className="project-item category-14 ">
                    <div className="projects-box">
                        <div className="projects-thumbnail">
                            <a href="portfolio-detail-slider.html">
                                <img src="images/projects-grid/project2.jpg" />
                            </a>
                            <div className="overlay">
                                <h5>Minimal Guests House</h5>
                                <i className="ot-flaticon-add" />
                            </div>
                        </div>
                        <div className="portfolio-info">
                            <div className="portfolio-info-inner">
                                <h5><a className="title-link" href="portfolio-detail-slider.html">Minimal Guests House</a></h5>
                                <p className="portfolio-cates">
                                    <a href="#">Decor</a>
                                    <a href="#">Interior</a>
                                </p>
                            </div>
                            <a className="overlay" href="portfolio-detail-slider.html" />
                        </div>
                    </div>
                </div>
                <div className="project-item category-15 ">
                    <div className="projects-box">
                        <div className="projects-thumbnail">
                            <a href="portfolio-left.html">
                                <img src="images/projects-grid/project3.jpg" />
                            </a>
                            <div className="overlay">
                                <h5>Art Family Residence</h5>
                                <i className="ot-flaticon-add" />
                            </div>
                        </div>
                        <div className="portfolio-info">
                            <div className="portfolio-info-inner">
                                <h5><a className="title-link" href="portfolio-left.html">Art Family Residence</a></h5>
                                <p className="portfolio-cates"><a href="#">Architecture</a></p>
                            </div>
                            <a className="overlay" href="portfolio-left.html" />
                        </div>
                    </div>
                </div>
                <div className="project-item category-20 ">
                    <div className="projects-box">
                        <div className="projects-thumbnail">
                            <a href="portfolio-right.html">
                                <img src="images/projects-grid/project4.jpg" />
                            </a>
                            <div className="overlay">
                                <h5>Private House in Spain</h5>
                                <i className="ot-flaticon-add" />
                            </div>
                        </div>
                        <div className="portfolio-info">
                            <div className="portfolio-info-inner">
                                <h5><a className="title-link" href="portfolio-right.html">Private House in Spain</a></h5>
                                <p className="portfolio-cates"><a href="#">Furniture</a></p>
                            </div>
                            <a className="overlay" href="portfolio-right.html" />
                        </div>
                    </div>
                </div>
                <div className="project-item category-19 ">
                    <div className="projects-box">
                        <div className="projects-thumbnail">
                            <a href="portfolio-small.html">
                                <img src="images/projects-grid/project5.jpg" />
                            </a>
                            <div className="overlay">
                                <h5>Modern Villa in Belgium</h5>
                                <i className="ot-flaticon-add" />
                            </div>
                        </div>
                        <div className="portfolio-info">
                            <div className="portfolio-info-inner">
                                <h5><a className="title-link" href="portfolio-small.html">Modern Villa in Belgium</a></h5>
                                <p className="portfolio-cates"><a href="#">Furniture</a></p>
                            </div>
                            <a className="overlay" href="portfolio-small.html" />
                        </div>
                    </div>
                </div>
                <div className="project-item category-14 thumb2x">
                    <div className="projects-box">
                        <div className="projects-thumbnail">
                            <a href="portfolio-big.html">
                                <img src="images/projects-grid/project-metro2.jpg" />
                            </a>
                            <div className="overlay">
                                <h5>Minimalistic Style Appartment</h5>
                                <i className="ot-flaticon-add" />
                            </div>
                        </div>
                        <div className="portfolio-info">
                            <div className="portfolio-info-inner">
                                <h5><a className="title-link" href="portfolio-big.html">Minimalistic Style Appartment</a></h5>
                                <p className="portfolio-cates"><a href="#">Furniture</a><a href="#">interior</a></p>
                            </div>
                            <a className="overlay" href="portfolio-big.html" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}