import { Link } from "react-router-dom";

export function AboutHome() {
    return (
        <section className="about-1">
            <div className="grid-lines grid-lines-vertical">
                <span className="g-line-vertical line-left color-line-default" />
                <span className="g-line-vertical line-center color-line-default" />
                <span className="g-line-vertical line-right color-line-default" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 mb-5 mb-lg-0">
                        <div className="about-img-1">
                            <img src="images/image2-home1.png" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 align-self-center">
                        <div className="about-content-1 ml-xl-70">
                            <div className="ot-heading is-dots">
                                <span>[ sobre nós ]</span>
                                <h2 className="main-heading">Extraordinary Spring</h2>
                            </div>
                            <p>
                                Sempre tivemos em mente algo além de uma simples construção, pensamos sobre os
                                vários significados impactantes que uma residência têm, como família, refúgio,
                                momentos inesquecíveis, começo de uma vida nova e realização de sonhos.
                                A Extraordinary Spring, com toda a sua expertise e paixão pelo que faz, quer fazer
                                com que estes estejam presentes na vida de nossos clientes, com um toque
                                arquitetônico especial e engenharia otimizada. E por fim, fazer da sua identidade
                                a nossa ao projetar a residência.
                            </p>
                            <div className="ot-button">
                                <Link to="/remodelacao" className="octf-btn octf-btn-dark">Visualizar Projeto</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}