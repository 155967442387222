import emailjs from '@emailjs/browser';
import { FormEvent, useRef, useState } from 'react';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export function Contact() {

    const MySwal = withReactContent(Swal);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const form = useRef<any>();

    const sendEmailForm = (e: FormEvent) => {
        e.preventDefault();

        emailjs.sendForm('service_awyt7s7', 'template_mbf8wlb', form.current, 'G9PY4PAaG4I011fDu')
            .then(() => {

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Enviado com sucesso!'
                })

                setName("");
                setEmail("");
                setMessage("");

            }).catch((err) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'error',
                    title: 'Tente novamente!'
                })
            });
    };

    return (
        <section className="contact-page">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 align-self-center mb-5 mb-lg-0">
                        <div className="contact-left">
                            <h2>Entrar em Contato</h2>
                            <p className="font14">Seu endereço de e-mail não será publicado. Os campos obrigatórios estão marcados com *</p>
                            <form ref={form} onSubmit={sendEmailForm} className="wpcf7">
                                <div className="main-form">
                                    <p>
                                        <label htmlFor="name"> <span className="error" id="err-name">please enter
                                            name</span></label>
                                        <input type="text" defaultValue={""} size={40} aria-invalid="false" placeholder="Seu Nome *" required
                                            name="name" value={name} onChange={(e) => { setName(e.target.value) }} />
                                    </p>
                                    <p>
                                        <label htmlFor="email">
                                            <span className="error" id="err-email">please enter e-mail</span>
                                            <span className="error" id="err-emailvld">e-mail is not a valid format</span>
                                        </label>
                                        <input type="email" id="email" defaultValue={""} size={40} aria-invalid="false" placeholder="Seu E-mail *" required
                                            name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                    </p>
                                    <p>
                                        <label htmlFor="message" />
                                        <textarea name="message" id="message" cols={40} rows={10} aria-invalid="false" placeholder="Mensagem..." required defaultValue={""}
                                            value={message} onChange={(e) => { setMessage(e.target.value) }} />
                                    </p>
                                    <p><button type="submit" id="send" className="octf-btn">Solicitar Contato</button></p>
                                    <div className="clear" />
                                    <div className="error" id="err-form">There was a problem validating the form please
                                        check!</div>
                                    <div className="error" id="err-timedout">The connection to the server timed out!</div>
                                    <div className="error" id="err-state" />
                                </div>
                            </form>
                            <div className="clear" />
                            <div id="ajaxsuccess">Successfully sent!!</div>
                            <div className="clear" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="contact-right">
                            <div className="ot-heading">
                                <span>[ detalhes do nosso contato ]</span>
                                <h2 className="main-heading">Vamos iniciar um projeto</h2>
                            </div>
                            <p>
                                Ligue para nós ou passe a qualquer momento, nos esforçamos para
                                responder a todas as perguntas dentro de 24 horas
                                em dias úteis. Teremos o maior prazer em responder suas perguntas.
                            </p>
                            {/* 
                            <div className="contact-info">
                                <i className="ot-flaticon-place" />
                                <div className="info-text">
                                    <h6>OUR ADDRESS:</h6>
                                    <p>411 University St, Seattle, USA</p>
                                </div>
                            </div>
                            */}
                            <div className="contact-info">
                                <i className="ot-flaticon-mail" />
                                <div className="info-text">
                                    <h6>NOSSO E-MAIL:</h6>
                                    <p><a href="mailto:extraordinaryspring.lda@gmail.com">extraordinaryspring.lda@gmail.com</a></p>
                                </div>
                            </div>
                            <div className="contact-info">
                                <i className="ot-flaticon-phone-call" />
                                <div className="info-text">
                                    <h6>NOSSO TELEFONE:</h6>
                                    <p><a href="tel:+351936628922">+351 936 628 922</a></p>
                                    <p><a href="tel:+351936628927">+351 936 628 927</a></p>
                                    <p><a href="tel:+351224039169">+351 224 039 169</a></p>
                                </div>
                            </div>
                            <div className="list-social">
                                <ul>
                                    <li><a href="https://www.facebook.com/extraordinaryspring" target="_blank"><i className="fab fa-facebook-f" /></a></li>
                                    <li><a href="https://www.instagram.com/extraordinaryspring/" target="_blank"><i className="fab fa-instagram" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}