import diogo from "../assets/diogo.webp";

export function Signature() {
    return (
        <section className="story-1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="story-text">
                            <p>“Mesmo que você não tenha um esboço pronto do que deseja – nós o ajudaremos a obter o resultado que você sonhou.”</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="story-author">
                            <img src={diogo} />
                            <div className="story-content">
                                <h5 className="story-title">Diogo Silva</h5>
                                <p className="story-description">CEO</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}