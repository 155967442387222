import { Link } from "react-router-dom";

export function Footer() {

    let newDate = new Date();
    let year = newDate.getFullYear();

    return (
        <div>
            <footer id="site-footer" className="site-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4 mb-xl-0">
                            <div className="widget-footer">
                                <img src="images/logo-footer.svg" className="footer-logo" />
                                <p>Nós fornecemos uma gama completa de Engenharia, Construção, Arquitetura e Remodelação </p>
                                <div className="footer-social list-social">
                                    <ul>
                                        <li><a href="https://www.facebook.com/extraordinaryspring" target="_blank"><i className="fab fa-facebook-f" /></a></li>
                                        <li><a href="https://www.instagram.com/extraordinaryspring/" target="_blank"><i className="fab fa-instagram" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4 mb-xl-0">
                            <div className="widget-footer">
                                <h6>Contacts</h6>
                                <ul className="footer-list">
                                    <li className="footer-list-item">
                                        <span className="list-item-icon"><i className="ot-flaticon-mail" /></span>
                                        <span className="list-item-text"><a href="mailto:extraordinaryspring.lda@gmail.com">extraordinaryspring.lda@gmail.com</a></span>
                                    </li>
                                    <li className="footer-list-item">
                                        <span className="list-item-icon"><i className="ot-flaticon-phone-call" /></span>
                                        <span className="list-item-text"><a href="tel:+351936628922">+351 936 628 922</a></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4 mb-md-0">
                            <div className="widget-footer widget-contact">
                                <h6>Veja Nossos Serviços</h6>
                                <ul>
                                    <li><Link to="/remodelacao">Remodelação</Link></li>
                                    <li><Link to="/engenharia-construcao">Engenharia - Construtora</Link></li>
                                    <li><Link to="/arquitetura">Arquitetura</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>{/* #site-footer */}
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-12 mb-4 mb-lg-0">
                            <p>{year} © Developer By
                                <a href="https://www.instagram.com/itrocketpt/" className="text-light" target="_blank"> IT Rocket</a>
                            </p>
                        </div>
                        <div className="col-lg-5 col-md-12 align-self-center">
                            <ul className="icon-list-items inline-items justify-content-lg-end">
                                <li className="icon-list-item inline-item">
                                    <a href="#"><span className="icon-list-text">Privacy Policy & Terms of Use</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}