import engenharia from "../assets/home/engenharia.webp"
import arquitetura from "../assets/home/arquitetura.webp"
import remodelacao from "../assets/home/remodelacao.webp"
import { Link } from "react-router-dom";

export function SpaceHome() {
    return (
        <div>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 px-0">
                        <div className="cate-lines h-light">
                            <div className="cate-item">
                                <Link to="/engenharia-construcao">
                                    <img src={engenharia} />
                                </Link>
                                <div className="cate-item_content">
                                    <Link to="/engenharia-construcao"><h2>Engenharia<span className="number-stroke">01</span></h2></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 px-0">
                        <div className="cate-lines h-light">
                            <div className="cate-item">
                                <Link to="/arquitetura">
                                    <img src={arquitetura} />
                                </Link>
                                <div className="cate-item_content">
                                    <Link to="/arquitetura"><h2>Arquitetura<span className="number-stroke">02</span></h2></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 px-0">
                        <div className="cate-lines s-light">
                            <div className="cate-item">
                                <Link to="/remodelacao">
                                    <img src={remodelacao} />
                                </Link>
                                <div className="cate-item_content">
                                    <Link to="/remodelacao"><h2>Remodelação<span className="number-stroke">03</span></h2></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}